/**
 * Created by gantushig on 5/19/20.
 */

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import _ from "lodash"
import "bootstrap/dist/css/bootstrap.min.css"
import "./header.scss";
import mng from "./mongolia.png"

import {Container, Navbar, Nav, NavDropdown, Form, FormControl, Button} from "react-bootstrap"

const Header = ({ siteTitle }) => (

    <Navbar bg="light" expand="lg">
        <Container>
        <Navbar.Brand style={{paddingTop:"5px"}} href="/">
            {siteTitle}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="ml-auto">
                <Nav.Link style={{textDecoration:"none"}} href="/"><span>Home</span></Nav.Link>
                <Nav.Link style={{textDecoration:"none"}} href="/contact"><span>Contact</span></Nav.Link>
                <Nav.Link style={{textDecoration:"none", height:"20px"}} href="#"><img src={mng} width={20}/></Nav.Link>
            </Nav>
        </Navbar.Collapse>
        </Container>
    </Navbar>

)
Header.propTypes = {
    siteTitle: PropTypes.string,
}
Header.defaultProps = {
    siteTitle: ``,
}
export default Header